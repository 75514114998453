<template>
  <div>
    <el-row v-if="activityType==1">
      <div class="showimg">
        <pic-zoom :url="imgurl" :scale="3"></pic-zoom>
      </div>
    </el-row>
    <el-row v-else>
      <!-- 限时购详情 -->
      <div class="showimg">
        <pic-zoom :url="imgurl" :scale="3"></pic-zoom>
      </div>
      <div class="wraper">
         <button @click="left" :disabled="imgIndex==0" :class="imgIndex==0?'active':''">
            <i class="el-icon-arrow-left"></i>
          </button>
                <ul id="imgBox">
                  <!-- <li @click="left" v-show='imgIndex!=0'> -->
                  <li
                    v-for="(item, index) in imgList"
                    :key="index"
                    @click="changeImage(index)"
                    :class="index==imgIndex?'imgActive':''"
                  >
                    <img :src="item" alt="图片"/>
                  </li>
                </ul>
         <button @click="right" :disabled="imgIndex>=imgList.length-1"  :class="imgIndex>=imgList.length-1?'active':''">
          <!-- <li @click="right" v-show='imgIndex<imgList.length-1'> -->
            <em class="el-icon-arrow-right"></em>
          </button>
      </div>
    </el-row>
  </div>
</template>

<script>
import PicZoom from "vue-piczoom";
export default {
  name: "ActivityModuleGalss",
  data() {
    return {
      imgurl:'',
      imgList: [],
      imgIndex:0,
      activityType:''
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
     ActivityType:{
      type:Number
    }
  },
  components: {
    PicZoom,
  },
  methods: {
    left:function(){
            if(this.imgIndex==0){
                return false
            }
            this.imgIndex--;
            this.imgurl = this.imgList[this.imgIndex];
             if(this.imgIndex>4){
              let left=parseInt(document.getElementById('imgBox').style.left)+78
              document.getElementById('imgBox').style.left=left+'px'
            }else{
               document.getElementById('imgBox').style.left=21+'px'
            }
    },
    right:function(){
            if(this.imgIndex>=this.imgList.length-1){
                return false
            }
            this.imgIndex++;
            this.imgurl = this.imgList[this.imgIndex];
            if(this.imgIndex>4){
              let left=-(Number(this.imgIndex)-4)*78+21
              document.getElementById('imgBox').style.left=left+'px'
            }
    },
    changeImage(index) {
      this.imgIndex=index;
      this.imgurl = this.imgList[index];
      
    },
  },
  created() {
    this.activityType=this.ActivityType
    if(this.activityType==1){
         this.imgurl=this.goodsinfo.pic
         this.imgList.push(this.imgurl)
      //  this.imgurl=this.goodsinfo.albumPics.split(",")[0]
      //  this.imgList=this.goodsinfo.albumPics.split(",")
    }else{

      // console.log('限时购详情',this.goodsinfo)
        this.imgurl=this.goodsinfo.images
         this.imgList.push(this.imgurl)
      //  this.imgList=this.goodsinfo.albumPics.split(",")
    }
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.showimg {
  width: 430px;
  height: 430px;
  border: 1px solid #ccc;
  .magnifier-box {
    position: relative;
    
    .mouse-cover {
      position: fixed !important;
      top: 200px !important;
      left: 200px !important;
      right: 40px;
      background: #eee !important;
    }
  }
  .mouse-cover {
    background: red !important;
  }
}
.wraper {
  padding: 0px 21px 10px;
  width: 432px;
  height: 100px;
  position: relative;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  button{
        top: 9px;
       width:20px!important;
      height: 82px!important;
      float: left;
      border: 1px solid #f6f6f6;
      margin: 0 3px 14px 3px;
      border: none;
      padding: 0;
      background: #fff;
      outline: none;
      z-index:99;
      cursor:pointer;
     &.active{
       pointer-events:none;
     }
    }
    button:first-child {
      left:-3px;
      .galss();
      i{
        font-size: 24px;
        font-weight: bold;
      }
    }
    button:last-child {
      margin-right: 0px;
      right: 0px;
      .galss();
       i{
        font-size: 24px;
        font-weight: bold;
      }
    }
  ul {
    position:absolute;
    left:21px;
    width:auto;
    height: 100%;
    padding-top: 14px;
        z-index: 2;
    li {
      width: 70px;
      height: 70px;
      float: left;
      border: 1px solid #f6f6f6;
      margin: 0 3px 14px 3px;
      &.imgActive {
        transform: translateY(-1px);  
        box-shadow: 0px 0px 3px rgb(255, 51, 51);
      }

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    
  }
}
</style>
